@import url('https://fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700');
 
/***GENERALE***/

/**html,body {
  overflow-x: hidden;
} CAUSA DOPPIA BARRA DI SCORRIMENTO**/

body,h1,h2,h3,h4,h5,h6,span,strong,bold,a,li {
  font-family: 'PT Sans Narrow',Arial, Helvetica, sans-serif !important;
}

h1,h2,a {
  font-family: 'PT Sans Narrow',Arial, Helvetica, sans-serif;
  font-weight:bold;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.welcome {
  font-weight: 400;
  color: #a71e3b;
  font-size: 60px;
  margin-bottom: 18px;
  margin-top: 20px;
}

.title-page {
  font-weight: 400;
  color: #a71e3b;
  font-size: 30px;
  margin-bottom: 18px;
  margin-top: 20px;
}


/***BOTTONI***/

.btn,.btn-primary {
  background-color: #a71e3c;
  border-radius: 3px;
}

.btn:focus,.btn:hover {
  border-radius: 3px;
  background: #B1B8BE;
  color: #000000;
}

.avanti {
  margin-top: 30px;
}

a.white {
  color: white;
  text-align: center;
  vertical-align: middle;
  padding-top: 15px;
  font-weight: bold;
  height: 50px;
}

a img {
  cursor: pointer;
}

.link {
  font-size: 15px;
  font-weight: bold;
  display: contents;
}

#donotcache-form-wrapper {
  margin-bottom: 0px;
}


/***DROPDOWN***/


.open>.dropdown-menu {
  display: block;
  background: #a71e3c;
  border-radius: 0;
}

.dropdown-menu li {
  border-right: none;
  width: auto;
}

.intro-text {
  font-weight: bold;
  color: #a71e3c;
  margin-bottom: 30px;
  font-size: 49px;
  text-align: center;
  width: 85%;
}

.navbar-collapse #block-menu-block-34 .nav>li>a, .navbar-collapse #block-menu-block-1 .nav>li>a {
  border-color: #ffffff;
  color: #ffffff;
  background: #a71e3b;
  font-family: inherit;
  padding-right: 20px;
  border-bottom: 1px solid white;
}

.nav span.caret {
  display: inline-block!important;
}

.open>.dropdown-menu {
  display: block;
  background: #a71e3c;
  border-radius: 0;
  margin-top: 0;
  border: none;
  box-shadow: none;
}

.dropdown-menu>li>a {
  color: white;
  font-size: 20px;
}

ul.dropdown-menu li {
  border-right: none!important;
  width: auto;
}



/***FORM***/


form label {
    /* labels are hidden */
  font-weight: bold;
}

form legend {
  font-size: 1.2em;
  margin-bottom: 12px;
}

.form-element-wrapper {
  margin-bottom: 12px;
}

.form-element {
  width: 85%;
  font-weight: bold;
  padding: 10px 12px;
  border: none;
  font-size: 14px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.form-element:focus {
  outline: dotted thin;
}

.form-field {
  color: #757575;
  ;																																				
     border: 1px solid #B7B7B7;
}

.form-field-focus, .form-field:focus, input[type="text"]:focus,select:focus {
  color: #333;
  border: 1px dotted gray;
}

.form-button {
  background: #A1143E;
  FONT-SIZE: 18px;
  color: #ffffff;
  cursor: pointer;
}

.form-button:hover {
  background: #B1B8BE;
  color: #000000;
}

.form-error {
  padding: 0;
  color: #B61601;
}

/*
input[type=checkbox] {
  vertical-align: middle;
  width: 10px;
  margin: 0px;
}

input {
  width: 85% !IMPORTANT;
}

*/
input[type=date],select {
  -webkit-appearance: unset;
}

  
 

/***VALIDATION***/

.validation {
  padding: 10px;
  margin-top: 10px;
}

.validation p {
  color: #ff2000;
  font-weight: bold;
  margin: 0;
}

.has-error {
  border: 1px solid red;
  box-shadow: 0 0 0 3px red inset;
}
/****
.has-error{
	background: #fff09f!important;
    padding-bottom: 20px!important;
	
	
}
***/



/***BOTTONE SPID***/
 
/*	
.spid-idp-button {
  position: initial!important;
  z-index: 1039;
}

.spid-idp-button .spid-idp-button-menu, .spid-idp-button .spid-idp-button-panel {
  border: none;
  box-shadow: none;
}

.spid-idp-button-link {
  border-bottom: 1px solid #bbbbbb;
  padding-top: 5px;
  padding-bottom: 5px;
}

.modal img {
  height: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button-spid {
  background-color: #06C;
  color: #FFF;
  width: 100%;
  border-radius: 5px;
}

.italia-it-button-text {
  padding: .95em 1em .85em 1em;
  font-size: 1.15em;
  text-align: center;
  FLOAT: NONE;
}*/


 

/****MEDIA QUERIES***/

/***<380px***/

@media (max-width:380px) {
  #separator {
    border-top: 5px solid #a71e3c;
    width: 100%;
    margin-top: 50px!important;
  }

  .logo img {
    width: 60px;
  }

  h1.header {
    margin-top: -0px;
  }

  h2.intro-text {
    margin-bottom: 30px;
    font-size: 23.7px;
  }

  header a.bicocca {
    font-size: 14px;
  }

  .footer_text {
    font-size: 16px;
  }
}

/***<640px***/

@media (max-width:640px) {
  #separator {
    border-top: 5px solid #a71e3c;
    width: 100%;
    margin-top: 0px;
  }

  header .nav .open>a, .nav .open>a:hover, .nav .open>a:focus {
    background-color: transparent;
    border-color: #A71E3B;
    text-align: right!important;
    font-family: inherit;
  }

  ul.menu.nav > li {
    float: none;
  }

  li {
    list-style: none;
    text-align: right;
  }

  h1.header {
    position: absolute;
    right: 15px;
    top: -15PX;
  }

  a.bicocca {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
  }

  .intro-text {
    margin-bottom: 30px;
    font-size: 26px;
  }

  .form-wrapper {
    margin-top: 8%;
    padding-bottom: 100px;
    margin-bottom: 100px;
    padding: 0;
  }

  .form-group {
    margin-bottom: 20px;
    padding: 0;
    margin-left: 0px;
    margin-right: 0px;
  }

  .modal-footer .btn {
    width: 100%;
    margin-top: 10px;
    font-size: 16px;
    /* margin-left: 5px; */;
  }

  .modal-footer .btn+.btn {
    margin-bottom: 0;
    margin-left: 0;
    /* margin-left: 5px; */;
  }

  .cache-consent {
    font-size: 14px;
  }

  #seconda-colonna {
    margin-top: 20px;
  }

  .footer_text {
    font-size: 16px;
  }

  h1.header {
    position: absolute;
    right: 15px;
    top: 35px;
  }
  
  #navbar {
    height: 100px;
  }

  #navbar img {
    width: 80px;
    margin-top: 5px;
  }

  .footer_text {
    font-size: 13px;
  }
}


/***>641px <991px***/

@media (min-width:641px) and (max-width:991px) {
	
  #seconda-colonna {
    margin-top: 20px;
  }

  .footer_text {
    font-size: 16px;
  }


}
 
/*** >768px***/


@media (min-width:768px) {
  .navbar-collapse {
    top: 80px;
  }

  .footer_text {
    font-size: 13px;
  }
} 

/***>992***/

@media (min-width: 992px) {
  .navbar-default .navbar-collapse {
    padding-bottom: 0;
    border-bottom: none;
    width: 100%;
  }

  .menu.nav .navbar-collapse .nav > li > a, .navbar-collapse .nav > li > span {
    font-family: inherit!important;
    font-size: 21px;
    text-align: right;
    border-top: 1px solid #a71e3b;
  }
  
  

  #block-menu-block-11 .nav>li>a {
    font-family: 'PT Sans Narrow', sans-serif;
    color: #ffffff;
    padding: 20px;
    font-weight: bold;
  }

  #block-menu-block-11 li {
    border-right: none;
    width: auto;
  }

  .footer_text {
    font-size: 13px;
  }
}



 

/***>640px  <1199px***/


@media (min-width:640px) and (max-width:1199px) {
  #separator {
    border-top: 5px solid #a71e3c;
    width: 100%;
    margin-top: 0px;
  }

  ul.menu.nav > li {
    float: none;
  }

  ul.menu.nav > li.home {
    float: left;
  }

  li {
    list-style: none;
    text-align: left;
  }
     

h1.header {
  position: absolute;
  right: 15px;
  top: 35px;
}

a.bicocca {
  font-size: 30px;
  font-weight: bold;
  color: #000000;
}

.form-wrapper {
  margin-top: 8%;
  padding-bottom: 100px;
  margin-bottom: 100px;
  padding: 0;
}

.footer_text {
  font-size: 13px;
}
	 
	
}


/***>640px***/
@media (min-width:640px) {
  #navbar {
    height: 100px;
  }

  #navbar img {
    width: 80px;
    margin-top: 5px;
  }

  .footer_text {
    font-size: 13px;
  }
}


/***>768px***/

@media (min-width:768px) {
     
  .card {
    border: 1px solid #bbbbbb;
  }

  .footer_text {
    font-size: 13px;
  }
}


/***<1024px***/

@media (max-width:1024px) {
  .nav>li>a {
    color: white;
  }

  .nav>li.home {
    float: left!important;
    font-weight: bold;
    border-right: 1px solid;
  }

  p.footer_text {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .footer_text {
    font-size: 13px;
  }
}


/***768px X 1024px***/

@media (device-width:768px) and (device-height:1024px) {
	
  .form-wrapper {
    min-height: 590px!important;
  }

  .footer_text {
    font-size: 13px;
  }
}


/***<1199px***/


@media (max-width:1199px) {
	
  .navbar-default .navbar-collapse {
    padding-bottom: 0;
    border-bottom: none;
  }

  .footer_text {
    font-size: 13px;
  }

  p.footer_text {
    padding-top: 20px;
    padding-bottom: 20px;
  }  
}


/***>1200px***/



@media (min-width:1200px) {
  h1.header {
    position: absolute;
    right: 0;
    margin-top: 35px;
  }

  a.bicocca {
    font-size: 35px;
    color: #000000;
    font-weight: bold;
    margin-right: 15px;
  }

  .intro-text {
    font-size: 49px;
  }

  form {
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 21px;
  }

  .form-wrapper {
    margin-top: 7.5%;
    padding-bottom: 100px;
    margin-bottom: 120px;
    padding: 0;
  }

  .center-block {
    padding-bottom: 6px;
  }

  #modal-form,.registration-form {
    padding-left: 0;
    padding-right: 0;
    height: auto;
  }

  .padding-bottom-17 {
    padding-bottom: 17px;
  }

  .card {
    border: 1px solid #bbbbbb;
  }

  .avatar {
    width: 100%;
    height: 160px;
  }

  .validation p {
    font-size: 18px;
  }

  .footer_text {
    font-size: 13px;
  }  

  p.footer_text {
    padding-top: 20px;
    padding-bottom: 20px;
  }  
}

.toplink-modal-title {
  font-size: 30px;
}

.popover{
    max-width: 100%; /* Max Width of the popover (depending on the container!) */
}


/***>1600px***/
/*
@media (min-width: 1600px) {
  html,body {
    overflow-y: hidden;
  }

  footer {
    position: fixed;
    width: 100%;
    bottom: 0;
  }
}*/


/*** 2048 X 1536px ***/ 


@media (width:2048px) and  (height:1536px) {
  .form-wrapper {
    margin-top: 20%!important;
    padding-bottom: 100px;
    margin-bottom: 120px;
    padding: 0;
  }

  .footer_text {
    font-size: 13px;
  }  

  p.footer_text {
    padding-top: 20px;
    padding-bottom: 20px;
  }  
}







 